import type { FourOFourQuery, LayoutQuery } from '../wp-generated/types';
import { PR_REPORT_URLS } from './prReportUrls';

export type LayoutData = {
  leftMenu: LeftMenu;
  rightMenu: RightMenu;
  footerMenu: FooterMenu;
  footerBottomMenu: FooterBottomMenu;
  footerIcons: FooterIcons;
  socialMediaLinks: SocialMediaLinks;
  headCode?: string;
  endOfBodyCode?: string;
};

export type PageTranslationLink = {
  locale?: string | null;
  slug?: string | null;
};

export type LeftMenu = NonNullable<
  NonNullable<LayoutQuery['leftHeaderMenu']>['nodes'][0]['menuItems']
>['nodes'];

export type RightMenu = NonNullable<
  NonNullable<LayoutQuery['rightHeaderMenu']>['nodes'][0]['menuItems']
>['nodes'];

export type FooterMenu = NonNullable<
  NonNullable<LayoutQuery['footerMenu']>['nodes'][0]['menuItems']
>['nodes'];

export type FooterBottomMenu = NonNullable<
  NonNullable<LayoutQuery['footerBottomMenu']>['nodes'][0]['menuItems']
>['nodes'];

export type FooterIcons = NonNullable<
  NonNullable<LayoutQuery['siteSettings']>['acfFields']
>['footerIcons'];

export type SocialMediaLinks = NonNullable<LayoutQuery['siteSettings']>['socialLinks'];

export type FourOFourPage = NonNullable<
  NonNullable<FourOFourQuery['fourOFour']>['acfFields']
>['fourOFour'];

const ignorePaths = new Set(PR_REPORT_URLS);

export const shouldIgnoreMenuRender = (pathname: string) => {
  return ignorePaths.has(pathname) || pathname.includes('custom-tour');
};
