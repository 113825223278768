import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/apps/public/app/honeybadger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/apps/public/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/apps/public/app/styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/apps/public/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/apps/public/components/Layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledComponentsRegistry"] */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/apps/public/lib/styled-components-registry.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/node_modules/next/font/local/target.css?{\"path\":\"app/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/silka-regular-webfont.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../fonts/silka-bold-webfont.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../fonts/silka-medium-webfont.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/silka-semibold-webfont.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../fonts/silka-black-webfont.woff2\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../fonts/silka-regularitalic-webfont.woff2\",\"weight\":\"normal\",\"style\":\"italic\"},{\"path\":\"../fonts/silka-blackitalic-webfont.woff2\",\"weight\":\"900\",\"style\":\"italic\"}],\"variable\":\"--font-silka\"}],\"variableName\":\"Silka\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/actions-runner/_work/mnd-publish-frontend/mnd-publish-frontend/node_modules/nextjs-toploader/dist/index.js");
