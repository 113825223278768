import { type Resource, type TFunction, createInstance, type i18n } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

export const supportedLngs = ['en', 'se-sv', 'de-de', 'dk-da', 'no-no'];

const rule = {
  numbers: [0, 1, 2],
  plurals: (num: number) => Number(num !== 1 ? 2 : num),
};

type ReturnTuple = [instance: i18n, promise: Promise<TFunction<'translation', undefined>>];

const cache: Record<string, ReturnTuple> = {};

export const initI18next = (lng: string, resources?: Resource): ReturnTuple => {
  if (cache[lng]) {
    return cache[lng];
  }
  const i18nInstance = createInstance();

  i18nInstance.use(initReactI18next);

  if (!resources) {
    i18nInstance.use(
      resourcesToBackend(
        (language: string, _: string) => import(`../../public/locales/${language}.json`),
      ),
    );
  }
  i18nInstance.on('initialized', () => {
    supportedLngs.map(locale => i18nInstance.services.pluralResolver.addRule(locale, rule));
  });

  const promise = i18nInstance.init({
    debug: false,
    resources,
    fallbackLng: false,
    lng,
    defaultNS: 'default',
    pluralSeparator: '.',
    ns: ['default'],
    interpolation: {
      escapeValue: false,
      prefix: '%{',
      suffix: '}',
    },
    lowerCaseLng: true,
    load: 'currentOnly',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'div', 'strong', 'b', 'i', 'p', 'ol', 'ul', 'li', 'code'],
    },
  });

  cache[lng] = [i18nInstance, promise];

  return [i18nInstance, promise];
};

export const getServerSideT = async (lng: string) => {
  const [i18nInstance, promise] = initI18next(lng);
  await promise;
  return i18nInstance.t;
};
